/**
 * Created by TapFun on 2019/3/1.
 */
import {reqGetPermissionButton} from '../api/system-api'

export default {
  PermissionButton() {
    return {
      add: false,
      edit: false,
      del: false,
      batchDel: false,
      view: false,
      export: false,
      release: false,
      downShelf: false,
      enable: false,
      disable: false,
      top: false,
      cancleTop: false,
      preview: false,
      addTop: false,
      addNext: false,
      download: false,
      cancleRecommend: false,
      recommend: false,
      handle:false,
    }
  },
  getPermissionButton(permissionId, permissionButton) {
    reqGetPermissionButton({permissionId: permissionId}).then((res) => {
      let buttonArray = res.data.data;
      buttonArray.forEach(function (obj) {
        if (obj.permissionUrl === 'add') {
          permissionButton.add = true;
        }
        if (obj.permissionUrl === 'edit') {
          permissionButton.edit = true;
        }
        if (obj.permissionUrl === 'del') {
          permissionButton.del = true;
        }
        if (obj.permissionUrl === 'batchDel') {
          permissionButton.batchDel = true;
        }
        if (obj.permissionUrl === 'view') {
          permissionButton.view = true;
        }
        if (obj.permissionUrl === 'export') {
          permissionButton.export = true;
        }
        if (obj.permissionUrl === 'release') {
          permissionButton.release = true;
        }
        if (obj.permissionUrl === 'downShelf') {
          permissionButton.downShelf = true;
        }
        if (obj.permissionUrl === 'enable') {
          permissionButton.enable = true;
        }
        if (obj.permissionUrl === 'disable') {
          permissionButton.disable = true;
        }
        if (obj.permissionUrl === 'top') {
          permissionButton.top = true;
        }
        if (obj.permissionUrl === 'cancleTop') {
          permissionButton.cancleTop = true;
        }
        if (obj.permissionUrl === 'preview') {
          permissionButton.preview = true;
        }
        if (obj.permissionUrl === 'addTop') {
          permissionButton.addTop = true;
        }
        if (obj.permissionUrl === 'addNext') {
          permissionButton.addNext = true;
        }
        if (obj.permissionUrl === 'download') {
          permissionButton.download = true;
        }
        if (obj.permissionUrl === 'recommend') {
          permissionButton.recommend = true;
        }
        if (obj.permissionUrl === 'cancleRecommend') {
          permissionButton.cancleRecommend = true;
        }
        if (obj.permissionUrl === 'handle') {
          permissionButton.handle = true;
        }
      });
    })
  }
}
