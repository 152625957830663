<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>数据字典</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="getDictionarys(0)" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="showAddDialog" icon="plus">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="dictionarys" highlight-current-row v-loading="listLoading" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="selsChange" style="width: 100%;" border>
        <!--<el-table-column type="selection" width="50"></el-table-column>-->
        <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" width="200" label="名称"></el-table-column>
        <el-table-column prop="code" width="300" label="编码"></el-table-column>
        <el-table-column prop="serialNumber" width="80" label="序号" align="center"></el-table-column>
        <el-table-column prop="parentId" width="400" label="父ID" :formatter="formatParentId"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="300"></el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small"  v-if="permissionButton.edit" @click="showEditDialog(scope.$index,scope.row)">编辑</el-button>
            <el-button type="danger" v-if="permissionButton.del" @click="delDictionary(scope.$index,scope.row)" :disabled="!scope.row.parentId" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <!--<el-button type="danger" size="small" @click="batchDeleteDictionary" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10,15,20,25,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total" style="float:right;">
      </el-pagination>
      </el-col>

      <el-dialog title="新增" :visible.sync="addFormVisible" width="30%" :close-on-click-modal="false">
        <el-form :model="addForm" label-width="80px" :rules="addFormRules" ref="addForm">
          <el-form-item label="名称" prop="name">
            <el-input v-model="addForm.name" placeholder="请输入名称" auto-complete="off" maxlength="25"></el-input>
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="addForm.code" placeholder="请输入编码" auto-complete="off" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="父ID" prop="parentId">
            <el-select v-model="addForm.parentId" placeholder="请选择" clearable>
              <el-option
                v-for="item in parentArray"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="序号" prop="serialNumber">
            <el-input v-model.number="addForm.serialNumber" auto-complete="off" placeholder="请输入序号" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input type="textarea" v-model="addForm.remark" :rows="4" placeholder="请备注" maxlength="100"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addFormVisible = false">取消</el-button>
          <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
        </div>
      </el-dialog>

      <el-dialog title="编辑" :visible.sync="editFormVisible" width="30%" :close-on-click-modal="false">
        <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm">
          <el-form-item label="名称" prop="name">
            <el-input v-model="editForm.name" auto-complete="off" placeholder="请输入名称" maxlength="25"></el-input>
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="editForm.code" auto-complete="off" placeholder="请输入编码" :disabled="!editForm.parentId" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="父ID" prop="parentId">
            <el-select v-model="editForm.parentId" placeholder="请选择" clearable>
              <el-option
                      v-for="item in parentArray"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="序号" prop="serialNumber">
            <el-input v-model.number="editForm.serialNumber" placeholder="请输入序号" auto-complete="off" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input type="textarea" v-model="editForm.remark" :rows="4" placeholder="请备注" maxlength="100"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="editFormVisible = false">取消</el-button>
          <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
        </div>
      </el-dialog>

    </el-col>
  </el-row>
</template>
<script>
  import {reqGetDictionaryList,reqDeleteDictionary,reqEditDictionary,reqBatchDeleteDictionary,reqAddDictionary} from '../../../api/system-api';
  import {reqGetDictionaryParentList} from '../../../api/system-api';
  import permission from '../../../common/permission';
  export default{
      data(){
          return{
              filters:{
                  name:''
              },
            parentArray:[],
            dictionarys:[],
            total:0,
            pageNum:1,
            pageSize:15,
            listLoading:false,
            sels:[],
            permissionButton:new permission.PermissionButton,
            editFormVisible: false,//编辑界面是否显示
            editLoading: false,
            editFormRules: {
              name: [
                {required: true,message: '请输入名称', trigger: 'blur'},
                { min: 1, max:25, message: '名称不能超过25个字符', trigger: 'blur' },
              ],
              code: [
                {required: true,message: '请输入编码', trigger: 'blur'},
                { min: 1, max: 100, message: '编码不能超过100个字符', trigger: 'blur' }
              ],
              // serialNumber: [
              //   {message: '请输入序号', required:'true', trigger: 'blur'},
              //   { min: 1, max: 11, message: '序号不能超过11位', trigger: 'blur' }
              // ],
              description:[
                {message: '请输入备注', trigger: 'blur'},
                { min: 1, max: 100, message: '备注不能超过100个字符', trigger: 'blur' }
              ],
            },
            editForm: {
              id: 0,
              name: '',
              code: '',
              remark: '',
              serialNumber:'',
              parentId: ''
            },
            addFormVisible: false,//新增界面是否显示
            addLoading: false,
            addFormRules: {
              name: [
                {required: true,message: '请输入名称', trigger: 'blur'},
                { min: 1, max: 50, message: '名称不能超过25个汉字', trigger: 'blur' },
              ],
              code: [
                {required: true,message: '请输入编码', trigger: 'blur'},
                { min: 1, max: 100, message: '编码不能超过100位', trigger: 'blur' }
              ],
              // serialNumber: [
              //   {required:'true', message: '请输入序号', trigger: 'blur'},
              //   { min: 1, max: 11, message: '序号不能超过11位', trigger: 'blur' }
              // ],
              description:[
                {message: '请输入备注', trigger: 'blur'},
                { min: 1, max: 100, message: '备注不能超过50个汉字', trigger: 'blur' }
              ],
            },
            addForm: {
              name: '',
              code: '',
              remark: '',
              serialNumber:'',
              parentId: ''
            }
          }
      },methods:{

      handleCurrentChange(val) {
        this.pageNum = val;
        this.getDictionarys();
      },
      handleSizeChange(val) {
        this.pageSize=val;
        this.getDictionarys();
      },
      goReset(){
          this.filters.name='';
          this.pageNum=1;
          this.getDictionarys();
      },
      getDictionarys(status) {
        let para = {
          pageNum: status===0?0:this.pageNum,
          pageSize: this.pageSize,
          name: this.filters.name
        };
        this.listLoading = true;
        reqGetDictionaryList(para).then((res) => {
          this.total = res.data.data.total;
          this.dictionarys = res.data.data.records;
          this.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },
      //删除
      delDictionary: function (index, row) {
        this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
          this.listLoading = true;
          let para = {id: row.id};
          reqDeleteDictionary(para).then(() => {
            this.listLoading = false;
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getDictionarys();
          });
        }).catch(() => {
        });
      },
      //显示编辑界面
      showEditDialog: function (index, row) {
          this.getParentDictionarys();
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      },
      //编辑
      editSubmit: function () {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.$confirm('确认提交吗？', '提示', {}).then(() => {
              this.editLoading = true;
              let para = Object.assign({}, this.editForm);
              reqEditDictionary(para).then(() => {
                this.editLoading = false;
                this.$message({
                  message: '提交成功',
                  type: 'success'
                });
                this.$refs['editForm'].resetFields();
                this.editFormVisible = false;
                this.getDictionarys();
              });
            }).catch(()=>{
                this.editLoading = false;
                this.$message({
                    message: '提交失败',
                    type: 'error'
                });
            })
          }
        });
      },
      showAddDialog: function () {
          this.getParentDictionarys();
        this.addFormVisible = true;
        this.addForm = {
          name: '',
          code: '',
          remark: '',
          parentId: ''
        };
      },
      //新增
      addSubmit: function () {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.addLoading = true;
            let para = Object.assign({}, this.addForm);
            reqAddDictionary(para).then(() => {
              this.addLoading = false;
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.$refs['addForm'].resetFields();
              this.addFormVisible = false;
              this.getDictionarys();
            }).catch(()=>{
                this.addLoading = false;
                this.$message({
                    message: '提交失败',
                    type: 'error'
                });
            })
          }
        });
      },
      //批量删除
      batchDeleteDictionary: function () {
        var ids = this.sels.map(item => item.id).toString();
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          let para = {ids: ids};
          reqBatchDeleteDictionary(para).then(() => {
            this.listLoading = false;
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getDictionarys();
          });
        }).catch(() => {

        });
      },
      getParentDictionarys:function(){
        var that=this;
        reqGetDictionaryParentList({}).then((res) => {
          that.parentArray = res.data.data;
//          that.parentArray.unshift({permissionId:1,permissionName:''});
        });
      },
      formatParentId: function (row) {
        var parentId = '';
        if(this.parentArray){
          this.parentArray.forEach(function (obj) {
              if (row.parentId == obj.id) {
                  return parentId = obj.name;
              }
          });
        }
        return parentId;
      },
    },mounted(){
      var permissionId = this.$route.query.permissionId;
      permission.getPermissionButton(permissionId,this.permissionButton);
      this.getParentDictionarys();
      this.getDictionarys();
    }
  }
</script>
